import React, { useState, useEffect, useRef } from 'react';
import './Main/faq.css';
import Header from './Main/Header';
import alanBtn from "@alan-ai/alan-sdk-web";
import { ChakraProvider } from '@chakra-ui/react';
import Faq from './Main/Faq';

function App() {

  const alanBtnInstance = useRef(null);
  const [index, setIndex] = useState(null);

  useEffect(() => {
      if (!alanBtnInstance.current) {
          alanBtnInstance.current = alanBtn({
            key: '4518ab48bcf7c38b46751c07a7ff91922e956eca572e1d8b807a3e2338fdd0dc/stage',
            onCommand: (commandData) => {
              if (commandData.command === 'gotoFaq') {
                  setIndex(commandData.faqId - 1)
                }
              }
          });
      }
  }, []);

  return (
    <>
      <Header />
      <div class="alan-btn"></div>
      <ChakraProvider>
        <Faq index={index} setIndex={setIndex} />
      </ChakraProvider>
    </>
  );
}

export default App;
